import * as React from "react";
import {seoData} from "../data/seo";
import {introData} from "../data/intro";
import {navData} from "../data/nav";
import {textData} from "../data/text";
import {accordionData, accordionLabel} from "../data/accordion";

import Seo from "../components/seo";
import IntroVideo from "../components/intro-video";
import Image from "../components/image";
import ContentNextPage from "../components/content-next-page";
import Footer from "../components/footer";
import Composition from "../components/composition";
import ContentText from "../components/content-text";
import ContentAccordion from "../components/content-accordion";
import ContentImageText from "../components/content-img-text";
import useWindowSize from "../helpers/UseWindowSize";
import NewComposition from "../components/composition/test";
import firstCompLeft from "../assets/images/beach-tower/beach-residences-compos_1_left.png";
import firstCompRight from "../assets/images/beach-tower/beach-residences-compos_1_right.png";
import secondCompLeft from "../assets/images/beach-tower/beach-tower-compos_1_left.png";
import secondCompRight from "../assets/images/compos/beach-tower-compos_3_right.png";
import thirdCompLeft from "../assets/images/compos/beach_compos_left_4.png";
import thirdCompRight from "../assets/images/beach-tower/beach-residences-compos_3_right.png";
import {CompositionWrapper} from "../components/composition/test/styled.index";
import leftFooter from "../assets/images/compos/beach_compos_footer_left.png";
import rightFooter from "../assets/images/compos/beach_compos_footer_right.png";
import leftFooterM from "../assets/images/compos/beach-compos_mob_0_left.png";
import rightFooterM from "../assets/images/compos/beach-compos_mob_0_right.png";


const BeachTowerResidencePage = () => {
  const {width} = useWindowSize();
  if (typeof window === `undefined`) {
    return(<></>);
  }
  return (
    <main>
      <Seo page={seoData.beachTowerRes}/>
      <IntroVideo page={introData.beachTowerRes}/>

      <CompositionWrapper>
        {width >= 1024 &&
            <NewComposition imgSrc={firstCompLeft} top={"0"} left={"-0%"} height={'55%'}/>}
        {width >= 1024 &&
            <NewComposition imgSrc={firstCompRight} top={"2%"} left={"85%"} height={'50%'}/>}
        <ContentAccordion
          label={accordionLabel.beachTowerResidences.label}
          accData={accordionData.beachResidences}
          type={"resMob"}
        />
        <Image
          imageName={"beach-res_1.jpg"}
          altText={"The Beach Tower"}
          padding={width >= 1885 ? "0 9.3%" : "0 85px"}
          paddingMob={"0"}
        />
      </CompositionWrapper>
      <CompositionWrapper>
        <ContentText
          bgClassName={"bg lp"}
          heading={textData.beachRes1.heading}
          text={textData.beachRes1.text}
        />
        {width >= 1024 &&
            <NewComposition imgSrc={secondCompLeft} top={"5%"} left={"-240px"} height={'94%'}/>}
        {width >= 1024 &&
            <NewComposition imgSrc={secondCompRight} top={"5%"} right={"0"} height={'90%'}/>}
        {width < 568 && <Composition imgName={"beach-residences-compos_mob_2.png"} align={"top"}/>}
        {width < 568 && <Composition imgName={"beach-residences-compos_mob_2_2.png"} align={"offBottom4"}/>}
      </CompositionWrapper>
      <Image
        imageName={"beach-res_2.jpg"}
        altText={"The Beach Tower"}
        padding={"0"}
        move={true}
      />
      <Image
        imageName={"beach-res_3.jpg"}
        altText={"The Beach Tower"}
        padding={width >= 1885 ? "9.3%" : "9.3% 85px"}
        paddingMob={"5.2%"}
        move={false}
      />
      <CompositionWrapper>
        {width >= 1024 && <NewComposition imgSrc={thirdCompLeft} bottom={width > 1300 ? "25vh" : "18vh"} left={"0"} height={'50vh'}/>}
        {width >= 1024 && <NewComposition imgSrc={thirdCompRight} top={"0"} right={"-85px"} height={'25%'}/>}
        <ContentImageText
          type={"row t2"}
          imgName={"beach-res_4.jpg"}
          text={textData.beachRes2.text}
        />
        <div className="pb-s"/>
        <ContentImageText
          type={"row-rev t3"}
          imgName={"beach-res_5.jpg"}
          text={textData.beachRes3.text}
        />
        <div className="pb-s"/>
      </CompositionWrapper>
      <CompositionWrapper>
        <section>
          <ContentNextPage
            nextTitle={navData.beachTowerFloorPlans.next}
            nextLink={navData.beachTowerFloorPlans.link}
            mobHeight={"420px"}
            textAlign={'center'}
            vertAlign={'center'}
          />
          {width < 600 && <NewComposition imgSrc={leftFooterM} bottom={width > 380 ? "-25px": "-30px"} left={width > 380 ? "0" : "-10px"} height={width > 400 ? '100vw' : '90vw'}/>}
          {width < 600 && <NewComposition imgSrc={rightFooterM} bottom={width > 380 ? "-25px": "-30px"} right={width > 380 ? "0" : "-10px"} height={width > 400 ? '100vw' : '90vw'}/>}
        </section>
        <Footer/>
        {width >= 1024 && <NewComposition imgSrc={leftFooter} bottom={"0"} left={width >= 1250? "0" : "-4%"} height={width> 1200 ? "88vh" : '80vh'}/>}
        {width >= 1024 && <NewComposition imgSrc={rightFooter} bottom={"0"} right={"0"} height={width> 1200 ? "88vh" : '80vh'}/>}
      </CompositionWrapper>
    </main>
  );
};

export default BeachTowerResidencePage;
